import React from 'react';

function Banner() {
  return (
    <div className="banner title color_white">
        FEATURED WORKS
    </div>
  );
}

export default Banner;
